import { Image } from '@crystallize/reactjs-components';
import { Box, useTheme } from '@mui/material';

import { mergeSx } from '~/utils/mui';
import { CrystallizeImageProps } from './types';

const CrystallizeImage = ({
    caption,
    altText,
    plainImage = true,
    showCaption = true,
    sx,
    ...props
}: CrystallizeImageProps) => {
    const theme = useTheme();

    let pCaption;

    if (showCaption) {
        pCaption = caption;
    }

    let pAlt = '';

    if (altText) {
        pAlt = altText;
    }

    return (
        <>
            <Box
                sx={
                    plainImage
                        ? mergeSx(
                              {
                                  img: {
                                      width: '100%',
                                      height: 'auto',
                                  },
                              },
                              sx,
                          )
                        : mergeSx(
                              {
                                  figure: {
                                      margin: '0',
                                  },
                                  img: {
                                      borderRadius: theme.radius.allLarge,
                                      boxShadow: theme.shadows[4],
                                      width: '100%',
                                      height: 'auto',
                                  },
                              },
                              sx,
                          )
                }
            >
                <Image loading="lazy" caption={pCaption} alt={pAlt} {...props} />
            </Box>
        </>
    );
};

export default CrystallizeImage;
